<template>
  <v-row>
    <v-col lg="2">
      <v-text-field
        v-model="data.name"
        :label="$t('serach-by-name')"
        outlined
      ></v-text-field>
    </v-col>
    <v-col lg="2">
      <v-text-field
        v-model="data.country_code"
        :label="$t('serach-by-country-code')"
        outlined
      ></v-text-field>
    </v-col>
    <v-col lg="2">
      <v-text-field
        v-model="data.iso"
        :label="$t('serach-by-iso')"
        outlined
      ></v-text-field>
    </v-col>
    <v-col lg="2">
      <v-icon @click="getData" color="primary" class="ma-2" x-large
        >mdi-magnify</v-icon
      >
      <v-icon @click="clearData" color="primary" class="ma-2" x-large
        >mdi-close</v-icon
      >
    </v-col>
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      data: {},
    };
  },
  methods: {
    getData() {
      for (let key in this.data) {
        if (this.data[key] == "") delete this.data[key];
      }
      this.$emit("getData", this.data);
    },
    clearData() {
      this.data = {};
      this.$emit("clearData");
    },
  },
  computed: {
    types() {
      return [
        {
          value: "manager",
          name: this.$t("managers"),
        },
        {
          value: "student",
          name: this.$t("students"),
        },
        {
          value: "teacher",
          name: this.$t("teachers"),
        },
      ];
    },
  },
};
</script>
